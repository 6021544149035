import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import fonts, { weights } from '@utils/fonts';

export const CardInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.alignStart ? 'flex-start' : 'center'};
  height: 100%;

  ${mq.gtsm`
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: -16px;
      border-right: 3px solid ${colors.B100};
      transform: translateX(1.5px);
    }
  `}
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 16px 0;
  border-bottom: 3px solid ${colors.B100};

  ${(props) => props.isLargeColumn &&
    css`
      grid-column: 1 / 3;
      grid-template-rows: repeat(${(props) => props.items}, 1fr);

      ${mq.gtsm`
        &::before {
          content: '';
          position: absolute;
          top: 32px;
          bottom: 32px;
          right: 0;
          border-right: 3px solid ${colors.B100};
          transform: translateX(1.5px);
        }
      `}
    `}

  ${mq.gtsm`
    padding: 32px 16px;
    border-bottom: 0;

    ${(props) => props.hasMultiple && css`
      display: grid;
      grid-row-gap: 16px;
    `}

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      border-bottom: 3px solid ${colors.B100};
      transform: translateY(1.5px);
    }
  `}
`;

export const InnerCard = styled.div`
  display: flex;
  position: relative;

  &:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: 3px solid ${colors.B100};
  }

  &:not(:first-child) {
    padding-top: 16px;
  }

  ${mq.gtsm`
    &:not(:last-child) {
      padding-bottom: 0;
      border-bottom: 0;
    }

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: -16px;
      left: 0;
      border-bottom: 3px solid ${colors.B100};
      transform: translateY(1.5px);
    }
  `}
`;

export const Wrapper = styled.div`
  ${mq.gtsm`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 -16px;
    padding-bottom: 32px;
  `}
`;

export const StoriesSearchStats = styled.div`
  text-align: left;

  span {
    font-size: 32px;
    font-family: ${props => fonts(props.theme.locale).SANS_CONDENSED};
    font-weight: ${weights.BOLD};
    line-height: 100%;

    ${mq.gtsm`
      font-size: 48px;
    `}
  }
`;
