import React from 'react';
import { connectStateResults } from 'react-instantsearch-dom';
import { useIntl } from '@utils/localize';
import { StoriesSearchStats } from './styles';

const StoriesGridWrapper = ({ searchResults, children }) => {
  const intl = useIntl();

  if (searchResults && searchResults.query) {
    return (
      <StoriesSearchStats>
        <span>&#8220;{searchResults.query}&#8221;</span> {searchResults.nbHits}{' '}
        {intl.formatMessage({ id: 'results' })}:
      </StoriesSearchStats>
    );
  } else {
    return children;
  }
};

export default connectStateResults(StoriesGridWrapper);
