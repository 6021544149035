import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-plugin-react-intl';
import truncate from 'lodash/truncate';
import { useMediaQuery } from 'react-responsive';
import get from 'lodash/get';

import { useLocalFormat } from '@utils/localize';
import { breakpoints } from '@utils/responsive';
import Image from '@components/Image';
import * as styled from './styles';

function ImagePreview({
  category,
  date,
  excerpt,
  title,
  heroImageExtended,
  selectCategory,
  size,
  slug,
  type,
  theme,
}) {
  const localFormat = useLocalFormat();
  const isMobile = useMediaQuery({ maxWidth: breakpoints.mobile - 1 });

  let excerptLength = get(excerpt, 'length');
  const bodyProps = {};
  const captionProps = {};
  const headingProps = {};

  if (size === 'small') {
    excerptLength = 64;
  }

  if (type === 'card') {
    captionProps.sizes = ['small', 'regular'];
    captionProps.theme = theme;
    headingProps.level = 300;
    bodyProps.size = 'small';
  }

  if (type === 'column') {
    excerptLength = get(excerpt, 'length');

    if (isMobile) {
      captionProps.size = 'small';
    }

    if (size === 'small' || isMobile) {
      bodyProps.size = 'small';
      captionProps.theme = theme;
      headingProps.level = 500;
    } else {
      headingProps.level = 400;
    }
  }

  if (type === 'sidebar') {
    captionProps.size = 'small';
    headingProps.level = 500;
  }

  return (
    <styled.Wrapper type={type}>
      {get(heroImageExtended, 'image.asset.fluid') && (
        <styled.ImageWrapper type={type}>
          <Link to={`/stories/${get(slug, 'current')}`} aria-label={title}>
            <Image fluid={heroImageExtended.image.asset.fluid} {...heroImageExtended} />
          </Link>
        </styled.ImageWrapper>
      )}
      <styled.Content type={type}>
        <styled.Header size={size} type={type}>
          <styled.Caption {...captionProps}>
            {category && (
              <span onClick={() => selectCategory(category.slug.current)}>
                {category.title}
              </span>
            )}
            {date &&
              localFormat(new Date(date), 'LLL d, yyyy')}
          </styled.Caption>
        </styled.Header>
        <Link to={`/stories/${get(slug, 'current')}`} aria-label={title}>
          <styled.Heading {...headingProps}>{title}</styled.Heading>
          {type !== 'sidebar' && (
            <styled.Body {...bodyProps}>
              {truncate(excerpt, {
                length: excerptLength,
                separator: /,? +/,
              })}
            </styled.Body>
          )}
        </Link>
      </styled.Content>
    </styled.Wrapper>
  );
}

ImagePreview.propTypes = {
  excerpt: PropTypes.string,
  heading: PropTypes.string,
  heroImage: PropTypes.object,
  type: PropTypes.oneOf(['card', 'column', 'sidebar']),
  theme: PropTypes.oneOf(['blue', 'green', 'orange', 'purple', 'yellow']),
};

export default ImagePreview;
