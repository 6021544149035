import React from 'react';

import ImagePreview from '@components/storyPreview/Image';
import QuotePreview from '@components/storyPreview/Quote';
import TextPreview from '@components/storyPreview/Text';
import * as styled from './styles';

const cardTypes = {
  image: <ImagePreview />,
  quote: <QuotePreview />,
  text: <TextPreview />,
};

const cardProps = [
  { include: [0] },
  { include: [1] },
  { include: [2, 3, 4] },
  { include: [] },
  { include: [] },
  { include: [5] },
  { include: [6] },
  { include: [7] },
  { include: [8, 9, 10], type: 'largeColumn' },
  { include: [] },
  { include: [] },
  { include: [11] },
  { include: [12] },
  { include: [13] },
  { include: [14] },
];

const storyLayoutProps = [
  { type: 'card' },
  { type: 'card' },
  { type: 'column', size: 'small' },
  { type: 'column', size: 'small' },
  { type: 'column', size: 'small' },
  { type: 'card' },
  { type: 'card' },
  { type: 'card' },
  { type: 'column' },
  { type: 'column' },
  { type: 'column' },
  { type: 'card' },
  { type: 'card' },
  { type: 'card' },
  { type: 'card' },
];

function StoriesGrid({ handleFilter, stories }) {
  const getCardStories = (page, position) => {
    return cardProps[position].include.map((idx) => {
      const story = stories[idx + page * 15];

      if (!story) {
        return null;
      }

      const child = React.cloneElement(cardTypes[story.preview || 'text'], {
        ...story,
        ...storyLayoutProps[position],
        selectCategory: (slug) => {
          handleFilter(slug);
        },
      });

      if (cardProps[position].include.length > 1) {
        return <styled.InnerCard key={story._id}>{child}</styled.InnerCard>;
      }

      const layoutProps = storyLayoutProps[position];
      return (
        <styled.CardInner
          alignStart={
            layoutProps.type === 'card' && layoutProps.size !== 'small'
          }
          key={story._id}
        >
          {child}
        </styled.CardInner>
      );
    });
  };

  const getStoryCard = (story, idx) => {
    const page = Math.floor(idx / 15);
    const position = idx % 15;
    const items = cardProps[position].include.filter((idx) =>
      Boolean(stories[idx + page * 15]),
    );

    if (cardProps[position].include.length) {
      return (
        <styled.CardWrapper
          key={idx}
          hasMultiple={items.length > 1}
          isLargeColumn={cardProps[position].type === 'largeColumn'}
          items={items.length}
        >
          {getCardStories(page, position)}
        </styled.CardWrapper>
      );
    }

    return null;
  };

  return <styled.Wrapper>{stories.map(getStoryCard)}</styled.Wrapper>;
}

export default StoriesGrid;
