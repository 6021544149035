import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-plugin-react-intl';
import get from 'lodash/get';

import { useLocalFormat } from '@utils/localize';
import { Heading, Quote } from '@components/type';
import * as styled from './styles';

function QuotePreview({
  category,
  date,
  featuredQuote,
  selectCategory,
  slug,
  size,
  colorTheme,
  type,
}) {
  const localFormat = useLocalFormat();

  const layouts = {
    card:
      size === 'small' ? (
        <Heading level={300}>&ldquo;{featuredQuote}&rdquo;</Heading>
      ) : (
        <Quote>&ldquo;{featuredQuote}&rdquo;</Quote>
      ),
    column:
      size === 'small' ? (
        <Heading level={400}>&ldquo;{featuredQuote}&rdquo;</Heading>
      ) : (
        <Heading levels={[400, 200]}>&ldquo;{featuredQuote}&rdquo;</Heading>
      ),
    sidebar: <Heading level={500}>&ldquo;{featuredQuote}&rdquo;</Heading>,
  };

  return (
    <styled.Wrapper isSidebar={type === 'sidebar'}>
      <styled.Header>
        <styled.Caption size={type === 'sidebar' ? 'small' : 'regular'} colorTheme={colorTheme}>
          {category && (
            <span onClick={() => selectCategory(category.slug.current)}>
              {category.title}
            </span>
          )}
          {date && localFormat(new Date(date), 'LLL d, yyyy')}
        </styled.Caption>
      </styled.Header>
      <Link to={`/stories/${get(slug, 'current')}`} aria-label={featuredQuote}>
        {layouts[type]}
      </Link>
    </styled.Wrapper>
  );
}

QuotePreview.propTypes = {
  featuredQuote: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.oneOf(['card', 'column', 'sidebar']),
};

export default QuotePreview;
