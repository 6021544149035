import React from 'react';
import styled, { css } from 'styled-components';

import { useBreakpoint } from '@utils/responsive';

const SpaceComponent = styled.div`
  ${props => props.size === 'xsmall' && css`
    height: 40px;
  `}

  ${props => props.size === 'small' && css`
    height: 64px;
  `}

  ${props => props.size === 'regular' && css`
    height: 96px;
  `}

  ${props => props.size === 'large' && css`
    height: 148px;
  `}
`;

function Space({ size, sizes }) {
  const responsiveSize = useBreakpoint(sizes);
  const space = size || responsiveSize || 'regular';

  return <SpaceComponent size={space} />;
};

export default Space;
