import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import { weights } from '@utils/fonts';
import { mq } from '@utils/responsive';
import { Caption as UnstyledCaption } from '@components/type';

export const Caption = styled(UnstyledCaption)`
  & span {
    display: inline-block;
    margin-right: 16px;
    color: ${(props) => getThemeStyles(props.colorTheme).text};
    font-weight: ${weights.BOLD};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Header = styled.div`
  margin-bottom: 16px;

  ${mq.gtsm`
    margin-bottom: 24px;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${props => props.isSidebar && css`
    flex-direction: column-reverse;
    padding-bottom: 16px;
    border-bottom: 1px solid ${colors.O100};

    & ${Header} {
      margin-bottom: 0;
      margin-top: 8px;
    }
  `}
`;

function getThemeStyles(theme) {
  switch (theme) {
    case 'blue':
      return {
        background: colors.B100,
        border: colors.B200,
        text: colors.B500,
      };
    case 'green':
      return {
        background: colors.G100,
        border: colors.G200,
        text: colors.G500,
      };
    case 'orange':
      return {
        background: colors.O100,
        border: colors.O200,
        text: colors.O500,
      };
    case 'purple':
      return {
        background: colors.P100,
        border: colors.P200,
        text: colors.P500,
      };
    default:
      return {
        background: colors.Y100,
        border: colors.Y200,
        text: colors.B500,
      };
  }
}
