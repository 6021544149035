import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import { weights } from '@utils/fonts';
import { mq } from '@utils/responsive';
import {
  Body as UnstyledBody,
  Caption as UnstyledCaption,
  Heading as UnstyledHeading,
} from '@components/type';

export const Body = styled(UnstyledBody)`
  display: none;

  ${mq.gtsm`
    display: block;
  `}
`;

export const Caption = styled(UnstyledCaption)`
  & span {
    display: inline-block;
    margin-right: 16px;
    color: ${(props) => getThemeStyles(props.colorTheme).text};
    font-weight: ${weights.BOLD};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Content = styled.div`
  flex: 1 1 100%;

  ${(props) =>
    props.type === 'sidebar' &&
    css`
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
    `}
`;

export const Header = styled.div`
  ${(props) => getHeaderMargin(props.size, props.type)};
`;

export const Heading = styled(UnstyledHeading)`
  margin-bottom: 8px;
`;

export const ImageWrapper = styled.div`
  position: relative;
  flex: 1 0 ${(4 / 12) * 100}%;

  ${(props) =>
    props.type === 'card' &&
    css`
      margin-bottom: 24px;
    `}

  ${(props) =>
    props.type !== 'card' &&
    css`
      margin-left: 16px;
    `}

  ${(props) =>
    props.type === 'sidebar' &&
    css`
      flex: 1 0 25%;
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props) =>
    props.type !== 'card' &&
    css`
      flex-direction: row-reverse;
      justify-content: space-between;
    `}

  ${(props) =>
    props.type === 'column' &&
    css`
      align-items: center;
    `}

  ${(props) =>
    props.type === 'sidebar' &&
    css`
      justify-content: flex-end;
      padding-bottom: 16px;
      border-bottom: 1px solid ${colors.B100};
    `}
`;

function getHeaderMargin(size, type) {
  if (type === 'card') {
    return css`
      margin-bottom: 16px;
    `;
  }

  if (type === 'column') {
    if (size === 'small') {
      return css`
        margin-bottom: 16px;
      `;
    }

    return css`
      margin-bottom: 24px;
    `;
  }
}

function getThemeStyles(theme) {
  switch (theme) {
    case 'blue':
      return {
        background: colors.B100,
        border: colors.B200,
        text: colors.B500,
      };
    case 'green':
      return {
        background: colors.G100,
        border: colors.G200,
        text: colors.G500,
      };
    case 'orange':
      return {
        background: colors.O100,
        border: colors.O200,
        text: colors.O500,
      };
    case 'purple':
      return {
        background: colors.P100,
        border: colors.P200,
        text: colors.P500,
      };
    default:
      return {
        background: colors.Y100,
        border: colors.Y200,
        text: colors.B500,
      };
  }
}
